<template>
  <div>
    <b-row
      align-h="between"
      align-v="center"
      class="ml-1"
    >
      <b-col
        lg="2"
        md="3"
        sm="4"
        xs="12"
      >
        <b-form-group class="mb-1">
          <label class="d-inline-block text-sm-left mr-50">{{
            $t("common.perPage")
          }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="8"
        lg="8"
      >
        <b-row align-h="end">
          <b-col
            cols="12"
            lg="6"
            md="6"
          >
            <v-select
              v-model="search"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryOptions"
              label="name"
              :clearable="false"
              class="mb-0 pr-0 item-selector-title"
              :placeholder="$t('common.select_item')"
              @input="onFiltered"
            />
          </b-col>
          <b-col
            cols="12"
            lg="6"
            md="6"
          >
            <b-form-group
              label-cols-sm="1"
              label-align-sm="right"
              label-for="filterInput"
              class="mr-1"
            >
              <b-input-group>
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  :placeholder="$t('common.search')"
                  class="form-control-merge mx-2"
                  type="search"
                />

                <b-button
                  v-if="isAdmin"
                  type="reset"
                  variant="primary"
                  :to="{ path: 'products/new' }"
                >
                  {{ $t("common.new") }}
                </b-button>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div
      :key="key"
      class="mx-2 mt-1"
    >
      <b-table
        striped
        hover
        responsive
        class="position-relative rows-responsive"
        :per-page="perPage"
        :current-page="currentPage"
        :items="filteredProducts"
        :fields="headers"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        :busy="isBusy"
        show-empty
        @filtered="onFiltered"
        @row-clicked="editItem"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #empty="scope">
          <b-row align-h="center">
            <span>{{ scope.emptyText }}</span>
          </b-row>
        </template>

        <template #cell(name)="cellName">
          <div class="d-flex align-items-center name-cell">
            <b-avatar
              size="lg"
              class="mr-1"
              :src="`${url}/images/${cellName['item'].filename}`"
            />
            <div class="d-flex flex-column">
              <p>
                {{ cellName["item"].name }}
              </p>
              <p class="text-muted">
                {{ cellName["item"].code }}
              </p>
            </div>
          </div>
        </template>

        <template #cell(sell_price)="cellName">
          <span>€ {{ formatNumberEuro(cellName["item"].sell_price) }}</span>
        </template>

        <template #cell(buy_price)="cellName">
          <span>€ {{ formatNumberEuro(cellName["item"].buy_price) }}</span>
        </template>

        <template #cell(active)="cellStatus">
          <b-badge :variant="status[1][cellStatus.value]">
            {{ status[0][cellStatus.value] }}
          </b-badge>
        </template>

        <template #cell(dependencies_qnt)="cellDep">
          {{
            cellDep.item.products[0]
              ? cellDep.item.products[0].dependencies_qnt
              : 0
          }}
          <b-button
            v-if="isAdmin"
            variant="danger"
            class="btn-icon ml-4"
            @click="deleteProduct(cellDep.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
    </div>

    <b-card-body
      class="d-flex justify-content-between align-items-center flex-wrap pt-0"
    >
      <!-- pagination -->
      <div v-if="!isBusy && currentPage != null && totalRows != 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @change="page => updateCurrentPage(page)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <!-- page length -->
      <span
        class="d-none d-md-block"
      >{{ $t("common.displaying") }} {{ perPage * currentPage }}
        {{ $t("common.of") }} {{ filteredProducts.length }}
        {{ $t("common.registers") }}.</span>
    </b-card-body>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  BCol,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import dbProduct from '@/models/product'
import env from '@/@helpers/envs'

export default {
  name: 'Products',
  components: {
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCardBody,
    BCol,
    BRow,
    BSpinner,
    vSelect,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: () => false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      key: 0,
      headers: JSON.parse(JSON.stringify(this.fields)),
      search: { id: '-1', name: `${this.$i18n.t('products.all')}` },
      perPage: 50,
      pageOptions: [25, 50, 100, 500],
      totalRows: null,
      currentPage: 5,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      url: env.baseURL,
      /* eslint-disable global-require */
      status: [
        {
          1: 'ACTIVE',
          0: 'INACTIVE',
        },
        {
          1: 'light-success',
          2: 'light-danger',
        },
      ],
    }
  },
  computed: {
    categoryOptions() {
      const obj = this.$store.state.app.system?.products?.categories
      const map = new Map()
      if (obj) {
        Object.keys(obj).forEach(key => {
          map.set(key, obj[key])
        })
        const array = []
        map.forEach((val, key) => {
          array.push({ id: Number(key), name: val })
        })
        array.unshift({ id: '-1', name: `${this.$i18n.t('products.all')}` })
        return array
      }
      return []
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    isAdmin() {
      return (
        JSON.parse(window.localStorage.userData).role === 'ADMIN'
      )
    },
    filteredProducts() {
      return this.search.name !== `${this.$i18n.t('products.all')}`
        ? this.data.filter(product => product.category
          ?.toLowerCase()
          .includes(this.search?.name?.toLowerCase()))
        : this.data
    },
  },
  watch: {
    data(value) {
      this.totalRows = value.length
    },
  },
  beforeUpdate() {
    this.fieldLanguage()
  },
  created() {
    if (this.$route.query.page) {
      this.currentPage = Number(this.$route.query.page)
    } else {
      this.currentPage = 1
    }
    if (this.$route.query.category) this.search = { id: '-1', name: this.$route.query.category }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.filteredProducts.length
    this.fieldLanguage()
  },
  methods: {
    updateCurrentPage(page) {
      this.$router.replace({ query: { page } })
      this.currentPage = page
    },
    async deleteProduct(id) {
      this.$bvModal.msgBoxConfirm(this.$t('products.confim_delete_product.body'), {
        title: this.$t('products.confim_delete_product.title'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            dbProduct.delete(id)
              .then(result => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${this.$i18n.t('products.product_deleted')}`,
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: result.data.message,
                  },
                })
                this.$nextTick(() => {
                  this.$emit('refresh', id)
                })
              })
          }
        })
    },
    fieldLanguage() {
      this.fields.forEach((header, index) => {
        this.headers[index].label = `${this.$i18n.t(this.fields[index].label)}`
      })
    },
    editItem(item) {
      this.$router.push(`/products/products/${item.id}?page=${Number(this.$route.query.page) || 1}`)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.$router.replace({ query: { page: 1 } })
    },
    formatNumberEuro(number) {
      return Number(number).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
  },
}
</script>

<style scoped>
.name-cell p {
  margin: 0;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
