<template>
  <b-card-code>
    <b-card-body>
      <products-table
        v-if="showTable"
        :data="items"
        :fields="fields"
        :is-busy="isBusy"
        @refresh="update"
      />
    </b-card-body>
  </b-card-code>
</template>

<script>
import { BCardBody } from 'bootstrap-vue'
import BCardCode from '@/views/drs/components/BCardCode.vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductsTable from '@/views/drs/products/components/ProductsTable.vue'
import dbProduct from '@/models/product'

export default {
  components: {
    BCardCode,
    BCardBody,
    ProductsTable,
  },
  data() {
    return {
      /* eslint-disable global-require */
      items: [],
      isBusy: false,
      perPage: 50,
      search: '',
      showTable: true,
    }
  },
  computed: {
    role() {
      return localStorage.getItem('userData')
    },
    fields() {
      const fields = [
        { key: 'name', label: 'products.name', sortable: true },
        { key: 'category', label: 'products.category', sortable: true },
        // { key: 'brand', label: 'products.brand', sortable: true },
        { key: 'active', label: 'common.status', sortable: true },
        { key: 'sell_price', label: 'products.sell_price', sortable: true },
      ]
      if (JSON.parse(localStorage.getItem('userData')).role === 'ADMIN') {
        fields.push({
          key: 'buy_price',
          label: 'products.buy_price',
          sortable: true,
        })
      }
      fields.push({
        key: 'dependencies_qnt',
        label: 'common.dependency',
        sortable: true,
      })
      return fields
    },
  },
  async created() {
    await this.$store.dispatch('app/fetchSystem')
    this.isBusy = true
    this.items = await dbProduct.get({
      page: '',
      perPage: this.perPage,
      paginate: false,
    })
    this.items.forEach(e => {
      e.category = this.$store.state.app.system?.products.categories[
        e.products_categories_id
      ]
      e.brand = this.$store.state.app.system?.products.categories[
        e.products_brands_id
      ]
    })
    this.isBusy = false
  },
  methods: {
    update(event) {
      this.items.splice(this.items.map(e => e.id).indexOf(event), 1)
    },
  },
}
</script>
